import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'

import srcMoon from '@/assets/images/circle-red.png'
import srcHeart from '@/assets/images/heart-red.png'
import { Section, Cell } from './Testimonials.css'

const CoursesIndexTestimonials = ({ content }) => {
  const testimonials =
    flatMap(content?.testimonials, x => x?.testimonial?.document)?.map(
      x => x?.data,
    ) || []

  if (!testimonials.length) return null

  return (
    <Section>
      <UI.Parallax top='0' right='240px' force={1.1} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcHeart}
            alt={content.hero_duration_title}
            width='320'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Parallax top='-400px' left='-160px' force={1.3} offsetComp={30}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcMoon}
            alt={content.hero_duration_title}
            width='320'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <UI.Grid>
          <Cell>
            <UI.TestimonialGroup color='orange' testimonials={testimonials} />
          </Cell>
        </UI.Grid>
      </UI.Container>
    </Section>
  )
}

export default CoursesIndexTestimonials
