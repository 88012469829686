import React from 'react'
import * as UI from '@/components/UI'
import {
  Section,
  TextWrapper,
  List,
  Item,
  ItemImage,
  ItemTitle,
} from './Future.css'

const CoursesIndexFuture = ({ content }) => {
  return (
    <Section>
      <UI.Container>
        <TextWrapper>
          <UI.SectionTitle>{content.future_title}</UI.SectionTitle>
          <UI.SectionDescription>
            {content.future_description}
          </UI.SectionDescription>
        </TextWrapper>
        <List>
          {content.future_list.map(({ title, image }) => (
            <Item key={title}>
              <ItemImage src={image} />
              <ItemTitle>{title}</ItemTitle>
            </Item>
          ))}
        </List>
      </UI.Container>
    </Section>
  )
}

export default CoursesIndexFuture
