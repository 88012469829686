import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import srcCheeto from '@/assets/images/cheeto-red.png'
import { Section, Cards, CardGrid, CardCell } from './Featured.css'

const CoursesIndexFeatured = ({ content }) => {
  const featured =
    flatMap(content?.featured, x => x?.course?.document)?.map(x => ({
      ...x?.data,
      slug: x?.slug,
    })) || []
  if (!featured.length) return null
  return (
    <Section>
      <UI.Container>
        <UI.Parallax top='42%' right='20%' force={1.3} offsetComp={30}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
              src={srcCheeto}
              alt={content.hero_duration_title}
              width='400'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <Cards>
          <CardGrid>
            {featured?.map(x => {
              return (
                <CardCell key={x.slug}>
                  <UI.Card
                    to={`/${content.slug}/${x.slug}`}
                    color='orange'
                    title={x.meta_title}
                    description={x.meta_description}
                    image={x?.thumbnail}
                    tags={x.tags}
                    metricLabel={x.metric_label}
                    metricValue={x.metric_value}
                    metricDescription={x.metric_description}
                    isCourses
                  />
                </CardCell>
              )
            })}
          </CardGrid>
        </Cards>
      </UI.Container>
    </Section>
  )
}

export default CoursesIndexFeatured
