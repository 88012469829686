import React from 'react'
import * as UI from '@/components/UI'

import srcMoon from '@/assets/images/circle-red.png'
import srcHeart from '@/assets/images/heart-red.png'
import { Section, Grid, Cell, DurationTitle } from './Hero.css'

const CoursesIndexHero = ({ content }) => {
  return (
    <Section>
      <UI.Parallax top='12%' right='150px' force={1.1} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcMoon}
            alt={content.hero_duration_title}
            width='320'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Parallax bottom='10%' left='-60px' force={1.1} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcHeart}
            alt={content.hero_duration_title}
            width='360'
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <Grid>
          <Cell>
            <UI.PageTitle>{content.hero_title}</UI.PageTitle>
            <UI.SectionDescription style={{ maxWidth: 'none' }}>
              {content.hero_description}
            </UI.SectionDescription>
            <DurationTitle>{content.hero_duration_title}</DurationTitle>
            <UI.SmallText>{content.hero_duration}</UI.SmallText>
          </Cell>
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default CoursesIndexHero
