import React from 'react'
import * as UI from '@/components/UI'
import {
  Section,
  Grid,
  GalleryCell,
  TextCell,
  Title,
  List,
  Item,
} from './Gallery.css'

const CoursesIndexGallery = ({ content }) => {
  return (
    <Section>
      <UI.Container>
        <Grid>
          <GalleryCell>
            <UI.Gallery color='orange' gallery={content.gallery} />
          </GalleryCell>
          <TextCell>
            <Title>{content.gallery_title}</Title>
            <List>
              {content.gallery_list.map(({ item }) => (
                <Item key={item}>{item}</Item>
              ))}
            </List>
          </TextCell>
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default CoursesIndexGallery
