import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'

export const Section = styled.section`
  margin-bottom: 10rem;
  position: relative;
`

export const Cell = styled.div`
  width: ${getColumnSize(12)};
  ${above('sm')`
    width: ${getColumnSize(10)};
    margin-left: ${getColumnSize(1)};
   `}
  ${above('md')`
    width: ${getColumnSize(8)};
    margin-left: ${getColumnSize(2)};
   `}
  ${above('lg')`
    width: ${getColumnSize(6)};
    margin-left: ${getColumnSize(3)};
   `}
  ${above('xg')`
    width: ${getColumnSize(5)};
   `}
`
