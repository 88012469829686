import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding-bottom: 5.75rem;
  ${above('md')`
    padding-bottom: 7.5rem;
  `}
  ${above('lg')`
    padding-bottom: 9.5rem;
  `}
  ${above('xg')`
    padding-bottom: 10.75rem;
  `}
`

export const TextWrapper = styled.div`
  margin-bottom: 1.25rem;
  text-align: center;
  p {
    max-width: 100%;
  }
  ${above('sm')`
    width: ${getColumnSize(12)};
  `}
  ${above('md')`
    margin-bottom: 2.5rem;
    width: ${getColumnSize(8)};
    text-align: left;
  `}
  ${above('lg')`
    width: ${getColumnSize(6)};
    text-align: left;
  `}
  ${above('xg')`
    width: ${getColumnSize(5)};
    text-align: left;
  `}
`

export const List = styled(UI.Grid).attrs({
  as: 'ul',
  'vertical-gutter': true,
})`
  justify-content: center;
  padding-top: 0.75rem;
  ${above('md')`
    padding-top: 2.75rem;
    justify-content: flex-start;
  `}
`

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  ${above('md')`
    display: block;
    text-align: left;
  `}
`

export const ItemImage = styled(UI.Image)`
  width: 2.75rem;
  height: 2.75rem;
  margin-bottom: 0.5rem;
  ${above('md')`
    width: 3rem;
    height: 3rem;
  `}
  ${above('lg')`
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 0.75rem;
  `}
  ${above('xg')`
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
  `}
`

export const ItemTitle = styled.div`
  ${getTypeStyle('blogText')};
  max-width: 9em;
  font-family: ${p => p.theme.type.fonts.headline};
  ${above('md')`
    ${getTypeStyle('caption')};
  `}
`
