import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layout'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Footer from '@/components/Partials/Footer/Footer'
import Hero from '@/components/contents/CoursesIndex/Hero'
import Featured from '@/components/contents/CoursesIndex/Featured'
import Future from '@/components/contents/CoursesIndex/Future'
import Gallery from '@/components/contents/CoursesIndex/Gallery'
import Testimonials from '@/components/contents/CoursesIndex/Testimonials'
import Prefooter from '@/components/Partials/Prefooter/Prefooter'
import getMetaImage from '@/utils/getMetaImage'

const CoursesIndexPage = ({ data }) => {
  const content = { ...data?.content?.data, slug: data?.content?.slug }

  const metaImage = getMetaImage(content)

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
    >
      <NavBar />
      <Hero content={content} />
      <Featured content={content} />
      <Future content={content} />
      <Gallery content={content} />
      <Testimonials content={content} />
      <Prefooter content={content} />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query CoursesIndex($locale: String) {
    content: prismicCoursesPage(lang: { eq: $locale }) {
      lang
      slug: uid
      data {
        meta_title
        meta_description
        meta_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        hero_title
        hero_description
        hero_duration_title
        hero_duration
        featured {
          course {
            document {
              ... on PrismicCourse {
                slug: uid
                data {
                  meta_title
                  meta_description
                  tags {
                    tag
                  }
                  metric_label
                  metric_value
                  metric_description
                  thumbnail {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          src
                          sizes
                          originalName
                          srcSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        future_title
        future_description
        future_list {
          title
          image {
            alt
            localFile {
              childImageSharp {
                fluid {
                  aspectRatio
                  originalName
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        gallery_title
        gallery_list {
          item
        }
        gallery {
          title
          image {
            alt
            localFile {
              childImageSharp {
                fluid {
                  aspectRatio
                  originalName
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        testimonials {
          testimonial {
            document {
              ... on PrismicTestimonial {
                data {
                  name
                  position
                  interview_text
                  interview_link
                  content {
                    html
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          originalName
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        prefooter_cards {
          card {
            document {
              ... on PrismicPrefootercard {
                data {
                  title
                  description
                  type {
                    uid
                    type
                    url
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CoursesIndexPage
