import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding: 1.5rem 0 8rem;
  position: relative;
  ${above('sm')`
    padding: 2rem 0 8rem;
  `}
  ${above('md')`
    padding: 4rem 0 7.5rem;
  `}
  ${above('lg')`
    padding: 6.25rem 0 7.5rem;
  `}
`

export const Grid = styled(UI.Grid)`
  justify-content: center;
`

export const Cell = styled.div`
  width: ${getColumnSize(12)};
  ${above('md')`
     width: ${getColumnSize(10)};
   `}
  ${above('lg')`
     width: ${getColumnSize(8)};
   `}
`

export const DurationTitle = styled(UI.Label)`
  text-transform: uppercase;
  color: ${p => p.theme.colors.gray};
  padding: 2.25rem 0 0.25rem;
  ${above('md')`
    padding: 1.75rem 0 0.25rem;
  `}
`
