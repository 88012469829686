import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  position: relative;
  z-index: 20;
  padding-bottom: 1rem;
  ${above('lg')`
    padding-bottom: 10rem;
  `}
`

export const Grid = styled(UI.Grid)`
  align-items: center;
  position: relative;
`

export const GalleryCell = styled.div`
  margin-left: -8px;
  width: 100vw;
  padding: 0;
  ${above('md')`
    width: 100%;
    margin: 0;
  `}
  ${above('lg')`
    width: ${getColumnSize(6)};
    & > * {
      margin-left: calc(-50vw + 100% + 1rem);
    }
  `}
`

export const TextCell = styled.div`
  width: ${getColumnSize(12)};
  padding-top: 3rem;
  padding-bottom: 3rem;
  ${above('sm')`
    width: ${getColumnSize(10)};
  `}
  ${above('md')`
    width: ${getColumnSize(8)};
  `}
  ${above('lg')`
    width: ${getColumnSize(5)};
    margin-left: ${getColumnSize(1)};
  `}
  ${above('xg')`
    width: ${getColumnSize(4)};
  `}
`

export const Title = styled.h2`
  font-family: ${p => p.theme.type.fonts.headline};
  font-weight: 500;
  opacity: 0.8;
  ${getTypeStyle('blogText')};
  ${above('sm')`
    opacity: 1;
    ${getTypeStyle('text')};
  `}
  ${above('md')`
    ${getTypeStyle('caption')};
  `}
`

export const List = styled.ul`
  margin-top: 2rem;
  ${above('md')`
    margin-top: 3rem;
  `}
`

export const Item = styled.li`
  margin-bottom: 1rem;
  ${getTypeStyle('smallText')};
  display: flex;
  position: relative;
  &:before {
    content: '—';
    color: ${p => p.theme.colors.orange};
    margin-right: 0.5rem;
    ${above('lg')`
      position: absolute;
      right: 100%;
    `}
  }
`
