import styled from 'styled-components'
import above from '@/utils/above'

export const Section = styled.section`
  padding-bottom: 6.25rem;
  position: relative;
  z-index: 20;
  ${above('md')`
    padding-bottom: 7rem;
  `}
  ${above('lg')`
    padding-bottom: 8.75rem;
  `}
`

export const Cards = styled.div`
  margin-bottom: 4rem;
  position: relative;
  z-index: 20;
  ${above('md')`
    margin-bottom: 7.5rem;
  `}
`

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 33px;
  align-items: stretch;
  height: 100%;
  ${above('md')`
  grid-template-columns: repeat(2, 1fr);
   `}
  ${above('lg')`
  grid-template-columns: repeat(3, 1fr);
  `}
`

export const CardCell = styled.div`
  height: auto;
  width: 100%;
`
